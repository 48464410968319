.App {
  font-family: /*'Montserrat',*/ sans-serif;
  text-align: center;
  padding-top: 10px;
  background-color: #c0c0e0;
  padding-bottom: 25px;
  font-size: calc(10px + 1.5vmin);
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-description {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 25px 0;
}

.App-description h3 {
  text-align: left;
  margin: 0;
}

.App h1,
h2,
h3,
a {
  font-family: "Crimson Text", serif;
  color: #221133;
}

.App-link {
  color: #61dafb;
}

.DropdownWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.DropName {
  min-width: 240px;
}

.DropName,
.DropItem {
  height: 40px;
  font-size: 18px;
  /* font-family: "Crimson Text", serif; */
}

.DropName {
  background-color: darkblue;
  color: white;
}

.DropName:hover {
  background-color: slateblue;
}

.DropdownContent {
  /* Display style is set in component. Either "flex" or "none." */
  flex-direction: column;
  min-width: 240px;
  overflow: auto;
}

.ButtonRow {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.SortButton {
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  padding: 10px;
  margin: 10px 10px 0 10px;
}

.SortDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SortDescription {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.SortDescription h3 {
  text-align: left;
  margin: 0;
}

.RowOfChips {
  display: flex;
  justify-content: center;
  background-color: white;
  flex-direction: row;
  margin: 0 auto;
  width: 90%;
  min-height: 100px;
}

.ColorChipContainer {
  width: 10%;
}

.ColorChip {
  height: 75px;
  margin: 2px;
  /* width: calc(10%-10px); */
  /*width: 100px;*/
  /*flex-grow: 1;*/
  font-size: 10px;
}

@media (min-width: 1024) {
  .App h1 {
    font-size: 48px;
  }
  h3 {
    font-size: 22px;
  }
  p {
    font-size: 16px;
  }
  .ColorChip {
    margin: 5px;
  }
  .DropdownContent {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
}
